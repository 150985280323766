import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SafeHtml} from "../safe-html.pipe";
import {TranslationSnippet} from "../translation-snippet.pipe";
import {NotFoundComponent} from "../not-found/not-found.component";

import {TranslationService} from "../translation.service";
import {GoogleAnalyticsService} from "../google-analytics.service";
import {WindowRefService} from "../window-ref.service";
import { ImgOpenerDirective } from '../img-opener.directive';
import { ImgLinkDirective } from '../img-link.directive';
import {LandingAnimationDirective} from "../landing-animation.directive";
import { MouseSwivelDirective } from '../mouse-swivel.directive';
import { YoutubeEmbedComponent } from '../youtube-embed/youtube-embed.component';

@NgModule({
  imports: [
    CommonModule
  ], 
  declarations: [
      TranslationSnippet,
      SafeHtml,
      NotFoundComponent,
      ImgOpenerDirective,
      ImgLinkDirective,
      LandingAnimationDirective,
      MouseSwivelDirective,
      YoutubeEmbedComponent
  ],
  providers: [
    WindowRefService,
    GoogleAnalyticsService,
    TranslationService,
  ],
  exports: [
    CommonModule,
    TranslationSnippet,
    SafeHtml,
    NotFoundComponent,
    ImgOpenerDirective,
    ImgLinkDirective,
    LandingAnimationDirective,
    MouseSwivelDirective,
    YoutubeEmbedComponent
  ]
})
export class WindfishCommonModule { }
