import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';

export interface OpenPosition {
  job_title: string
  job_desc: string
  more_info_link?: string
}

@Injectable({
  providedIn: 'root'
})
export class OpenPositionService {

  constructor(public httpClient: HttpClient) {}

  public async getOpenPositions(): Promise<OpenPosition[]> {
    return this.httpClient.get<OpenPosition[]>('/assets/content/open-positions.json').toPromise();
  }
}
