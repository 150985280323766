import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

function getWindow (): any {
    return window;
}

@Injectable()
export class WindowRefService {
  constructor(@Inject(PLATFORM_ID) private platformId: Object){}
  get nativeWindow (): any {
    if(isPlatformBrowser(this.platformId)){
      return getWindow();
    }else{
      return null;
    }
  }
}
