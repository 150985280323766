import { Injectable } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { Observable, Observer } from 'rxjs';
import { debounceTime } from "rxjs/operators";
import { environment } from "../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public debounceObs: Observer<any>;
  private isSpinning;

  constructor(private spinner: NgxSpinnerService) { 
    new Observable<any>((obs: Observer<any>) => {
      this.debounceObs = obs;
    })
    .pipe(debounceTime(environment.loader_debounce))
    .subscribe((_val) => {
      this.spinner.hide();
      this.isSpinning = false;
    });
  }
  
  public keepSpinning(): void {
    if(!this.isSpinning){
      this.spinner.show();
    }
    //hide if not debounced
    this.debounceObs.next(true);
  }
}
