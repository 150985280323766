import {Component, OnInit} from "@angular/core";
import {TranslationService} from "../translation.service";

@Component({
    selector: '.locale-select',
    templateUrl: "./localeselect.component.html",
    styleUrls: ['./localeselect.component.sass']
})
export class LocaleSelectComponent implements OnInit{
    public selectedLocale: string;
    public possibleLocales: string[];

    private reverseLocaleMap: Object = {};

    constructor(
        public transService: TranslationService
    ){}

    ngOnInit(){
        this.possibleLocales = Object.keys(this.transService.locales);
        this.selectedLocale = this.transService.reverseLocaleMap[this.transService.locale];
    }
}
