import { Directive, OnInit, Input, ElementRef } from '@angular/core';
import {WindowRefService} from "./window-ref.service";

@Directive({
  selector: '[app-tech-logo]',
})
export class ImgLinkDirective implements OnInit {
  @Input() link: string
  constructor(private el: ElementRef, private winRef: WindowRefService) { }

  ngOnInit() {
    if(this.winRef.nativeWindow){
      this.el.nativeElement.addEventListener("click", () => {
        this.winRef.nativeWindow.open(this.link, "_blank");
      });
    }
  }

}
