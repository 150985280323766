import { NgModule, Component, InjectionToken, Inject, PLATFORM_ID} from "@angular/core";
import {isPlatformBrowser} from "@angular/common";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import { WindfishCommonModule } from "./windfish-common/windfish-common.module";
import {ReactiveFormsModule} from "@angular/forms";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { BrowserModule, HammerModule } from "@angular/platform-browser";
import { RouterModule, ActivatedRouteSnapshot } from "@angular/router";

import {LandingComponent} from "./landing/landing.component";
import {LocaleSelectComponent} from "./localeselect/localeselect.component";
import {TranslationContentComponent} from "./translation-content/translation-content.component";
import {AppComponent} from "./app.component";
import {AppRoutingModule} from "./app-routing.module";
import { TeamMemberComponent } from './team-member/team-member.component';
import { StudioComponent } from './studio/studio.component';
import { JobsComponent } from './jobs/jobs.component';
import { OpenPositionComponent } from './open-position/open-position.component';

import { OpenPositionService } from './open-position.service';
import { TeamMemberService } from "./team-member.service";
import { TechLogoService } from "./tech-logo.service";
import { JobDescriptionComponent } from './job-description/job-description.component';
import { HannoverComponent } from './hannover/hannover.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { LoaderInterceptor } from "./loader.interceptor";
import { NavLinkComponent } from './nav-link/nav-link.component'; 
import { NavLinkService } from './nav-link.service';

@NgModule({
    imports: [
        WindfishCommonModule,
        AppRoutingModule,
        BrowserModule.withServerTransition({appId: "windfish-web"}),
        BrowserAnimationsModule,
        RouterModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgxSpinnerModule,
        HammerModule
    ],
    declarations: [
        AppComponent,
        LandingComponent,
        LocaleSelectComponent,
        TranslationContentComponent,
        TeamMemberComponent,
        StudioComponent,
        JobsComponent,
        OpenPositionComponent,
        JobDescriptionComponent,
        HannoverComponent,
        NavLinkComponent
    ],
    providers: [
      OpenPositionService,
      TeamMemberService,
      TechLogoService,
      NavLinkService,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: LoaderInterceptor,
        multi: true
      }
    ],
    bootstrap: [ AppComponent ]
})
export class AppModule {
  constructor(@Inject(PLATFORM_ID) private platformId: Object){
    if(isPlatformBrowser(this.platformId)){
      require('hammerjs');
      var hash = window.location.hash.replace('#!/', '');
      if (hash.length > 1) {
          history.pushState({}, "client redirect", hash);
      }
    }
  } 
};
