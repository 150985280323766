import {Component, OnInit, Input} from "@angular/core";
import {ActivatedRoute, ParamMap} from "@angular/router";
import {TranslationService} from "../translation.service";
import {markdown} from "markdown"; //(it's a bit fucking weird, but I'll take it)

@Component({
    template: `<div [innerHTML]="content | safeHtml"></div>`,
    selector: "translation-content",
  styleUrls: ['./translation-content.component.sass']
})
export class TranslationContentComponent implements OnInit{
    public content: string;
    @Input() page: string;

    constructor(
        private route: ActivatedRoute,
        private trans: TranslationService
    ){}

    public ngOnInit(): void {
      if(this.page){
        this.trans.getPageTranslation(this.page).then((cnt) => {
          this.content = cnt;
        }); 
      }else{
        this.route.paramMap.subscribe(async (params: ParamMap) => {
          this.content = await this.trans.getPageTranslation(params.get('name'));
        });
      }
        
    }
};
