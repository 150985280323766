import { Component, OnInit } from '@angular/core';
import {OpenPositionService, OpenPosition} from '../open-position.service';
@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.sass']
})
export class JobsComponent implements OnInit {
  public open_positions: OpenPosition[]

  constructor(public opSrv: OpenPositionService) { }

  public ngOnInit(): void {
    this.opSrv.getOpenPositions().then((open_positions: OpenPosition[]) => {
      this.open_positions = open_positions;
    })
  }

}
