<h1>
  RISE: Strategy Evolved
</h1>

<app-youtube-embed [youtube-id]="'x0vpWtcpAs4'"></app-youtube-embed>

<div class="credits">
  Filming: <a href="mailto:philbrownfilms@gmail.com">Phil Brown</a>
  / Audio: <a href="https://graish.bandcamp.com/" target="_blank">Craig Pearson</a> 
</div>
  

<translation-content [page]="'HANNOVER_INTRO'"></translation-content>

<h2>Powered By</h2>

<p>
  {{'POWERED_BY_INTRO' | translation | async}}
</p>

<ul class="powered-by">
  <li *ngFor="let logo of powered_by">
    <img 
      app-tech-logo 
      [link]="logo.ext_link" 
      [src]="'/assets/tech_logos/'+logo.logo_path" />
  </li>
</ul>

<p>
  {{'CHECK_OUT_GITHUB' | translation | async}}
  <a [href]="'https://github.com/orgs/windfish-studio'" target="_blank">GitHub</a>
</p>
