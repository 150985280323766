import { Component, OnInit, Input } from '@angular/core';
import { TeamMember } from "../team-member.service";

@Component({
  selector: 'app-team-member',
  templateUrl: './team-member.component.html',
  styleUrls: ['./team-member.component.sass']
})
export class TeamMemberComponent{
  @Input() member: TeamMember;
}
