import { Directive, ElementRef, OnInit } from '@angular/core';
import {WindowRefService} from "./window-ref.service";

@Directive({
  selector: '[app-img-opener]',
})
export class ImgOpenerDirective implements OnInit{

  constructor(private winRef: WindowRefService, private el: ElementRef) {}

  public ngOnInit(): void {
    if(this.winRef.nativeWindow){
      this.el.nativeElement.addEventListener("click", () => {
        let src = this.el.nativeElement.getAttribute("src");
        this.winRef.nativeWindow.open(src, "_blank");
      });
    }
  }
}
