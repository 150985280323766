import { Directive, HostListener, ElementRef, OnInit, OnDestroy} from '@angular/core';
import {WindowRefService} from "./window-ref.service";

class Vector2 {
  constructor(
    public x: number,
    public y: number
  ){}

  public length(){
    return Math.sqrt(Math.pow(this.x, 2) + Math.pow(this.y, 2));
  }

  public normalize(){
    const l = this.length()
    return new Vector2(this.x / l, this.y / l);
  }

  public mutableLerp(otherVec: Vector2, alpha: number = 0.2){
    this.x += (otherVec.x - this.x) * alpha
    this.y += (otherVec.y - this.y) * alpha
  }
}

const RAD_MAX = (Math.PI/2) / 4;
const ANIMATE_INTERVAL = 100;

@Directive({
  selector: '[app-mouse-swivel]'
})
export class MouseSwivelDirective implements OnInit, OnDestroy{
  private winCenter: Vector2
  private maxRadius: number
  private intervalId: number
  private trueRotation: Vector2
  private delayedRotation: Vector2

  constructor(
    private elRef: ElementRef, 
    private windowRef: WindowRefService
  ) { 
    this.trueRotation = new Vector2(0,0);
    this.delayedRotation = new Vector2(0,0);
  }

  ngOnInit(){
    this.setWindowCenter();
    if(this.windowRef.nativeWindow){ 
      const win = this.windowRef.nativeWindow;
      win.document.documentElement.style.setProperty('--swivel-interval', `${ANIMATE_INTERVAL}ms`);
      this.intervalId = win.setInterval(this.rotate.bind(this), ANIMATE_INTERVAL);
    }
  }

  ngOnDestroy(){
    if(this.windowRef.nativeWindow){ 
      this.windowRef.nativeWindow.clearInterval(this.intervalId);
    }
  }

  public rotate(){
    const el = this.elRef.nativeElement;

    //lerp towards trueRotation
    this.delayedRotation.mutableLerp(this.trueRotation);

    //actually perform rotation
    el.style.transform = `rotateY(${this.delayedRotation.x}rad) rotateX(${this.delayedRotation.y}rad)`;
  }

  @HostListener('window:resize')
  private setWindowCenter(){
    if(this.windowRef.nativeWindow == null){ return; }
    
    const win: any = this.windowRef.nativeWindow;
    const winDims: Vector2 = new Vector2(win.innerWidth, win.innerHeight);
    this.winCenter = new Vector2((winDims.x / 2), (winDims.y / 2));
    this.maxRadius = Math.min(this.winCenter.x, this.winCenter.y);
  }

  @HostListener('window:mousemove', ['$event.clientX', '$event.clientY'])
  private onMouseMove(clientX: number, clientY: number){
    if(this.windowRef.nativeWindow == null){ return; }
    
    const delta: Vector2 = new Vector2(
      clientX - this.winCenter.x,
      -(clientY - this.winCenter.y)
    );
    
    const dist: number = Math.min(this.maxRadius, delta.length());
    //decompose delta vector into x, y components based on maxRadius limited dist

    const nml = delta.normalize();
    const capped = new Vector2((dist * nml.x), (dist * nml.y));

    this.trueRotation = new Vector2(
      (capped.x / this.maxRadius) * RAD_MAX,
      (capped.y / this.maxRadius) * RAD_MAX
    );
  }
}
