import { Component, OnInit, Input, HostListener} from '@angular/core';
import { NavLink } from "../nav-link.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-nav-link',
  templateUrl: './nav-link.component.html',
  styleUrls: ['./nav-link.component.sass']
})
export class NavLinkComponent implements OnInit {
  @Input() link: NavLink 

  constructor(
    private router: Router
  ) { }
  
  @HostListener('click', ['$event.target'])
  private onClick(btn){
    this.router.navigateByUrl(this.link.ref);
  }

  ngOnInit(): void {
  }

}
