export const common_env = {
  "force_devblog_host": "windfishstudio.com",
  "devblog_dateformat": "MMM Do YYYY",
  "devblog_link_dateformat": "YYYYMMDDHHmmss",
  "devblog_items_per_page": 10,
  "loader_debounce": 200,
  "http_loader_bounce_interval": 100,
  "contactform_lambda_endpoint": "https://5kjsenjw51.execute-api.eu-west-3.amazonaws.com/default/windfish-contactform",
  "ga_tracking_id": "UA-2563267-3",
  "devblog_categories": {
    "GAME_DEV": "GAME DEV",
    "TECH": "TECH"
  }
};
