<h1>{{'WORK_WITH_US' | translation | async}}</h1>
<div class="intro">
  <p [innerHtml]="'JOBS_INTRO' | translation | async | safeHtml"></p>
  <p [innerHtml]="'LOCATION_BLURB' | translation | async | safeHtml"></p>
</div>

<h1>{{'OPEN_POSITIONS' | translation | async}}</h1>
<div class="open-positions">
  <app-open-position *ngFor="let pos of open_positions" [pos]="pos"></app-open-position>
  <span class="no-positions-available" *ngIf="open_positions && open_positions.length == 0">
    {{'NO_OPEN_POSITIONS_CALLOUT' | translation | async}}
  </span>
</div>

<div class="jobs-email">
  {{'JOB_RELATED_QUERIES' | translation | async}} 
  <a href="mailto:jobs@windfishstudio.com">jobs@windfishstudio.com</a>
</div>

