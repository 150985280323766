<h1>{{'MISSION_STATEMENT_TITLE' | translation | async}}</h1>
<p id="mission_statement">
  {{'MISSION_STATEMENT' | translation | async}}  
</p>

<h1>{{'OUR_TEAM' | translation | async}}</h1>
<app-team-member *ngFor="let member of teamMembers" [member]="member"></app-team-member>

<h1>{{'SPONSORS' | translation | async}}</h1>

<div class="sponsor">
  <div class="info">
    <h3 class="name">Kreitek</h3>
    <span class="subtext">{{'KREITEK_SUBTEXT' | translation | async}}</span>
    <span class="description"> 
      <a href="http://kreitek.org">Kreitek</a> {{'KREITEK_DESC' | translation | async}} 
    </span>
  </div>

  <div class="gallery">
    <img app-img-opener [src]="'/assets/kreitek/kreitek-panorama_1.jpg'" />
    <img app-img-opener [src]="'/assets/kreitek/kreitek-panorama_2.jpg'" />
    <img app-img-opener [src]="'/assets/kreitek/kreitek-panorama_3.jpg'" />
  </div>

  <div class="windfish-sponsor-logo">
    <img [src]="'/assets/kreitek/kreitek-logo.svg'" class="logo" />
  </div>
</div>
