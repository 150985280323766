import {environment} from "../environments/environment";
import {Injectable, OnInit} from "@angular/core";
import {WindowRefService} from "./window-ref.service";

@Injectable()
export class GoogleAnalyticsService {

  private tracking_id: string;
  private ga: Function;
  private window: any;
  private init_promise: Promise<any>;

  constructor(private wrs: WindowRefService){
    this.window = wrs.nativeWindow;
    this.tracking_id = environment.ga_tracking_id;
    this.init_promise = this.analyticsInit().then((ga: Function) => {
      this.ga = ga;
      return ga;
    });
  }
  
  public analyticsInit(): Promise<Function>{

    if(this.window === null){
      return Promise.resolve(() => {/*do nothing if we're in server mode*/})
    }
    
    this.window.eval(`
      (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
      (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
      m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
      })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
    `);

    this.window.ga('create', this.tracking_id, 'auto');

    return new Promise((res, rej) => {
      this.window.ga(() => {res(this.window.ga)})
    });

  }

  public registerPageView(page): void {
    this.init_promise.then(() => {
      this.ga('send', 'pageview', page); 
    });
  }
}
