import { RouterModule, Route } from "@angular/router";
import { NgModule } from "@angular/core";
import { TranslationContentComponent } from "./translation-content/translation-content.component";
import { LandingComponent } from "./landing/landing.component";
import { StudioComponent } from "./studio/studio.component";
import { JobsComponent } from "./jobs/jobs.component";
import { JobDescriptionComponent } from "./job-description/job-description.component";
import { HannoverComponent } from "./hannover/hannover.component";

const routes: Route[] = [
    {
      path: '',
      pathMatch: 'full', 
      redirectTo: '/landing'
    },

    {
      path: 'contact',
      loadChildren: () => import("./contact/contact.module").then(m => m.ContactModule) 
    },

    {
      path: 'devblog',
      loadChildren: () => import("./dev-blog/dev-blog.module").then(m => m.DevBlogModule) 
    },

    {
      path: 'studio',
      component: StudioComponent
    },

    {
      path: 'games',
      pathMatch: 'full', 
      component: HannoverComponent
    },

    {
      path: 'games/rise',
      pathMatch: 'full', 
      component: HannoverComponent
    },

    {
      path: 'jobs',
      pathMatch: 'full', 
      component: JobsComponent
    },

    {
      path: 'jobs/:name',
      component: JobDescriptionComponent
    },

    {
      path: 'pages/:name',
      component: TranslationContentComponent
    }, 

    {
      path: 'landing',
      component: LandingComponent
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

