import { Component, OnInit, Input } from '@angular/core';
import { OpenPosition, OpenPositionService } from "../open-position.service";

@Component({
  selector: 'app-open-position',
  templateUrl: './open-position.component.html',
  styleUrls: ['./open-position.component.sass']
})
export class OpenPositionComponent {
  @Input() pos: OpenPosition

  constructor() { }
}
