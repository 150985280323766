import { Component, OnInit } from '@angular/core';
import {TechLogo, TechLogoService} from '../tech-logo.service';

@Component({
  selector: 'app-hannover',
  templateUrl: './hannover.component.html',
  styleUrls: ['./hannover.component.sass']
})
export class HannoverComponent implements OnInit {
  public powered_by: TechLogo[]
  constructor(private tl: TechLogoService) { 
    this.powered_by = [];
  }

  ngOnInit() {
    this.tl.getTechLogos().then((logos: TechLogo[]) => {
      this.powered_by = logos;
    })
  }

}
