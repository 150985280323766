import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-job-description',
  templateUrl: './job-description.component.html',
  styleUrls: ['./job-description.component.sass']
})
export class JobDescriptionComponent implements OnInit {
  public page: string

  constructor(
    private route: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    this.route.paramMap.subscribe(async (params: ParamMap) => {
      this.page = params.get('name');
    });
  }

}
