import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";

export interface TeamMember {
  name: string
  title: string
  link?: string
}

@Injectable({
  providedIn: 'root'
})
export class TeamMemberService {

  constructor(private httpClient: HttpClient) { }

  public async getMembers(): Promise<TeamMember[]> {
    return this.httpClient.get<TeamMember[]>(`/assets/content/team-members.json`).toPromise();
  }
}
