import { Component, OnInit } from '@angular/core';
import {TeamMemberService, TeamMember} from "../team-member.service";

@Component({
  selector: 'app-studio',
  templateUrl: './studio.component.html',
  styleUrls: ['./studio.component.sass']
})
export class StudioComponent implements OnInit {
  public teamMembers: TeamMember[];

  constructor(private teamMemberService: TeamMemberService) {}

  async ngOnInit() {
    this.teamMembers = await this.teamMemberService.getMembers();
  }

}
