import { Injectable } from '@angular/core';

export interface NavLink {
  label?: string;
  transLabel?: string;
  sublinks?: NavLink[];
  ref: string;
}

@Injectable({
  providedIn: 'root'
})
export class NavLinkService {
  constructor() { }
}
