import {
  Component, 
  ElementRef, 
  HostListener, 
  OnDestroy, 
  OnInit,
  Directive,
  Input
} from "@angular/core";
import {WindowRefService} from "./window-ref.service";

type WindfishColor = "red" | "blue" | "yellow" | "white";

class Vector2 {
    constructor(
        public x: number = 0,
        public y: number = 0
    ){}

    public add(v: Vector2){
      this.x += v.x;
      this.y += v.y;
    }

    public reset(){
      this.x = 0;
      this.y = 0;
    }

    public random(space: number, min: number = 0, negProb: number = 0.5): Vector2{
      this.x = (Math.floor(Math.random() * space) + min) * ((Math.random() <= negProb)? -1 : 1);
      this.y = (Math.floor(Math.random() * space) + min) * ((Math.random() <= negProb)? -1 : 1);
      return this;
    }
}

const WHALE_DIMS = new Vector2(193.9, 80);
const BOUNCE_MIN_BOX = 500
const WHALE_HEIGHT = `${WHALE_DIMS.y}vh`;
const ANIMATE_INTERVAL = 200;
const SPEED_BOOST_INIT = 80;
const SPEED_BOOST_ATTN = 0.55;

interface OffscreenMeta {
  top: boolean,
  bottom: boolean,
  left: boolean,
  right: boolean
}

class Offscreen{
  public partial: OffscreenMeta
  public total: OffscreenMeta

  constructor(
    el: HTMLElement,
    parentEl: HTMLElement,
    margin: Vector2 = new Vector2()
  ){
    const rect: any = el.getBoundingClientRect();
    this.total = {
      top: rect.bottom < (-margin.y),
      bottom: rect.top > (parentEl.clientHeight + margin.y),
      left: rect.right < (-margin.x),
      right: rect.left > (parentEl.clientWidth + margin.x)
    }   

    this.partial = {
      top: rect.top < (-margin.y),
      bottom: rect.bottom > (parentEl.clientHeight + margin.y),
      left: rect.left < (-margin.x),
      right: rect.right > (parentEl.clientWidth + margin.x)
    }   
  }

  public isPartiallyOffscreen(){
    return this.isOffscreen(this.partial)
  }

  public isTotallyOffscreen(){
    return this.isOffscreen(this.total)
  }

  private isOffscreen(ofs: OffscreenMeta){
    return (ofs.top || ofs.bottom || ofs.left || ofs.right);
  }

}

interface AnimateOpts {
  parentEl: HTMLElement,
  startPos: Vector2,
  speed: Vector2,
  depth?: number,
  boosted?: boolean
}

interface LoopingOpts extends AnimateOpts {
  respawnType?: string
}

interface CloudOpts extends LoopingOpts {
  whichCloud: number,
  scale: string
} 

interface FloatingBoxOpts extends LoopingOpts {
  color: WindfishColor,
  dimensions: Vector2
}

interface BouncingOpts extends AnimateOpts {
  dims: Vector2
}

interface WhaleOpts extends AnimateOpts {
  color?: WindfishColor,
  whaleType: "inverted" | "outline",
  scale: string 
}

abstract class AnimatedElement {  
  protected intervalId: number;
  protected nativeElements: HTMLElement[] = [];
  protected transform: Vector2[] = [];
  protected parentEl: HTMLElement;
  protected startPos: Vector2;
  protected speed: Vector2;
  protected depth: number = 0;

  private boost: number;

  constructor(
    opts: AnimateOpts
  ){
    Object.assign(this, opts);
    this.boost = (opts.boosted)? SPEED_BOOST_INIT : 1;
  }

  public destroy(): void {
    for(let i = 0; i < this.nativeElements.length; i++){
        let el = this.nativeElements[i];
        this.parentEl.removeChild(el);
        delete this.nativeElements[i];
        clearInterval(this.intervalId);
    }
  }

  private addToScene(pos: Vector2, el: HTMLElement): void {
    el.style.top = `${pos.y}%`;
    el.style.left = `${pos.x}%`;

    this.nativeElements.push(el);
    this.transform.push(new Vector2(0,0));
    this.parentEl.appendChild(el);
  }

  public spawnElement(pos: Vector2 = this.startPos): void{
    this.addToScene(pos, this.createElement());
  }

  protected move(): void{
    const boost = Math.max(1, this.boost);
    this.boost = this.boost * SPEED_BOOST_ATTN
    const fps = (ANIMATE_INTERVAL / 1000);

    for(let i = 0; i < this.nativeElements.length; i++){
      let el = this.nativeElements[i];
      let trans = this.transform[i];

      trans.x += (this.speed.x*fps*boost);
      trans.y += (this.speed.y*fps*boost);

      el.style.transform = `translateX(${trans.x}px) translateY(${trans.y}px) translateZ(${this.depth}vh)`;
    }
  }

  public animate(): void {
      this.animationBehavior();
      this.intervalId = window.setInterval(this.animationBehavior.bind(this), ANIMATE_INTERVAL);
  }

  abstract createElement(): HTMLElement;
  abstract animationBehavior(): void;
}

abstract class BouncingElement extends AnimatedElement{
  private margin: Vector2
  constructor(
    opts: BouncingOpts
  ){
    super(opts);

    const rect: any = opts.parentEl.getBoundingClientRect();
    const obj_width = (opts.dims.x / 100) * rect.height;
    let margin_width = (obj_width - rect.width);
    if(margin_width < BOUNCE_MIN_BOX){
      margin_width = BOUNCE_MIN_BOX;
    }

    this.margin = new Vector2(margin_width, BOUNCE_MIN_BOX);
  }

  public animationBehavior(): void {
    this.checkBounce();
    this.move();
  }

  private bounce(axis: string): void{
    this.speed[axis] *= -1;
  }

  private checkBounce(): void {
    const el = this.nativeElements[0];
    const ofs = new Offscreen(el, this.parentEl, this.margin);

    if(this.speed.x < 0 && ofs.partial.left){
      this.bounce('x');
    } else if(this.speed.x > 0 && ofs.partial.right){
      this.bounce('x');
    } 

    if(this.speed.y < 0 && ofs.partial.top){
      this.bounce('y');
    } else if(this.speed.y > 0 && ofs.partial.bottom){
      this.bounce('y');
    }
  }

}

abstract class LoopingElement extends AnimatedElement{
  protected respawnType: string

  constructor(
    opts: LoopingOpts
  ){
    super(opts);
    this.respawnType = opts.respawnType || "partial";
  }

  private cullInvisible(): void {
    for(let i = 0; i < this.nativeElements.length; i++){
        let el = this.nativeElements[i];
        const ofs = new Offscreen(el, this.parentEl)

        const removeEl = () => {
            this.parentEl.removeChild(el);
            this.transform.splice(i, 1);
            this.nativeElements.splice(i, 1);
        }

        if(this.speed.x < 0 && ofs.total.left){
          removeEl();
        } else if(this.speed.x > 0 && ofs.total.right){
          removeEl();
        } else if(this.speed.y < 0 && ofs.total.top){
          removeEl();
        } else if(this.speed.y > 0 && ofs.total.bottom){
          removeEl();
        }
    }
  }

  private respawnOnOverlap(): void {
      for(let el of this.nativeElements){

          if(this.nativeElements.length >= 2)
              continue;

          const ofs = new Offscreen(el, this.parentEl)

          //spawn a new cloud on the other side of the screen if an edge touches the screen limit
          if(this.speed.x < 0 && ofs[this.respawnType].left){ 
              this.spawnElement(new Vector2(100, this.startPos.y));
          }else if(this.speed.x > 0 && ofs[this.respawnType].right){
              const xpct = (-el.offsetWidth / this.parentEl.clientWidth) * 100;
              this.spawnElement(new Vector2(xpct, this.startPos.y));
          }else if(this.speed.y < 0 && ofs[this.respawnType].top){
              this.spawnElement(new Vector2(this.startPos.x, 100));
          }else if(this.speed.y > 0 && ofs[this.respawnType].bottom){
              const ypct = (-el.offsetHeight / this.parentEl.clientHeight) * 100;
              this.spawnElement(new Vector2(this.startPos.x, ypct));
          }
      }
  }

  private checkRespawn(): void {
    if(this.nativeElements.length == 0){
      this.spawnElement();
    }else{
      this.respawnOnOverlap();
      this.cullInvisible();
    }
  }

  public animationBehavior(): void {
    this.checkRespawn();
    this.move();
  }

}

class CloudSvg extends LoopingElement{
    protected whichCloud: number;
    protected scale: string;

    constructor(
      opts: CloudOpts
    ){
      super(opts);
      Object.assign(this, opts);
    }


    public createElement(): HTMLElement {
        const el: HTMLElement = document.createElement('img');
        el.setAttribute('src', `assets/cloud_${this.whichCloud % 3}.svg`);
        el.setAttribute('class', `cloud cloud-${this.whichCloud}`);

        el.style.height = this.scale;
        return el;
    }

}

class WhaleSvg extends BouncingElement{
    protected color: WindfishColor;
    protected whaleType: "inverted" | "outline";
    protected scale: string;

    constructor(
      opts: WhaleOpts
    ){
      super(Object.assign(opts, {dims: WHALE_DIMS}));
      Object.assign(this, opts);
      this.color = opts.color || 'white'; 
    }

  public createElement(): HTMLElement{
    const el: HTMLElement = document.createElement('div');
    el.setAttribute('class', `whale ${this.color} ${this.whaleType}`);
      
      window.fetch(`/assets/whale_${this.whaleType}.svg`).then(async (resp) => {
        el.innerHTML = await resp.text();
      })

      el.style.height = this.scale;
      return el;
  }
}

class FloatingBox extends LoopingElement{
  protected color: WindfishColor
  protected dimensions: Vector2

  constructor(
    opts: FloatingBoxOpts
  ){
      super(opts);
      Object.assign(this, opts);
  }

  public createElement(): HTMLElement {
    const el: HTMLElement = document.createElement('div');
    el.setAttribute('class', `floating-box ${this.color}`);
    el.style.width = `${this.dimensions.x}%`;
    el.style.height = `${this.dimensions.y}%`;
    return el;
  }
}

@Directive({
  selector: "[app-landing-animation]"
})
export class LandingAnimationDirective implements OnInit, OnDestroy{
    private static NUM_CLOUDS: number = 4;
    private animatedElementAr: AnimatedElement[] = [];

    constructor(
        private el: ElementRef,
        private windowRef: WindowRefService
    ){}

    @Input('mobileMenuDisplayed') mobileMenuDisplayed: boolean;  

    @HostListener('window:resize')
    onResize(){
        this.ngOnDestroy();
        this.ngOnInit();
    }

    ngOnDestroy(){
        for(let el of this.animatedElementAr){
            el.destroy();
        }
    }

    ngOnInit(){
        if(this.windowRef.nativeWindow === null){
          //if ssr do nothing
          return;
        }

        this.windowRef.nativeWindow.document.documentElement.style.setProperty('--anim-speed', `${ANIMATE_INTERVAL}ms`);

        const parentEl = this.el.nativeElement;
        const cloudDepth = 20;

        this.animatedElementAr = [
            new CloudSvg({
              parentEl: parentEl,
              startPos: new Vector2(this.mobileMenuDisplayed? 100 : 10, 17),
              speed: new Vector2(-20,0),
              boosted: this.mobileMenuDisplayed,
              whichCloud: 2, 
              depth: cloudDepth,
              scale: "17vh"
            }),

            new CloudSvg({
              parentEl: parentEl,
              startPos: new Vector2(this.mobileMenuDisplayed? 100 : 60, 79),
              speed: new Vector2(-24,0),
              boosted: this.mobileMenuDisplayed,
              whichCloud: 1, 
              depth: cloudDepth,
              scale: "13vh"
            }),

            new CloudSvg({
              parentEl: parentEl,
              startPos: new Vector2(this.mobileMenuDisplayed? 100 : 60, 53),
              speed: new Vector2(30,0),
              boosted: this.mobileMenuDisplayed,
              whichCloud: 0, 
              depth: cloudDepth,
              scale: "7vh"
            }),

            new CloudSvg({
              parentEl: parentEl,
              startPos: new Vector2(this.mobileMenuDisplayed? 100 : 23, 39),
              speed: new Vector2(24, 0),
              boosted: this.mobileMenuDisplayed,
              whichCloud: 0, 
              depth: cloudDepth,
              scale: "7vh"
            })

        ];

        //need to calculate whale width in vw units
        const rect = parentEl.getBoundingClientRect();
        const aspect = rect.width / rect.height;
        
        const whale_width_vw = WHALE_DIMS.x / aspect;
        const rand_mob_startx = (orig_val) => {
          if(!this.mobileMenuDisplayed){
            return orig_val
          }

          const x = Math.random();
          if(x > 0.5){
            return 100
          }else{
            return -whale_width_vw
          }
        }

        const inverted_pos: Vector2[] = [
          new Vector2(rand_mob_startx(11),-20), 
          new Vector2(rand_mob_startx(70),30), 
          new Vector2(rand_mob_startx(0),50)]

        for(let i = 0; i < 3; i++){
            let rand_v = (new Vector2()).random(40, 10);
            const whale = new WhaleSvg({
              parentEl: parentEl,
              startPos: new Vector2(rand_mob_startx(20+i), 30+i),
              speed: rand_v,
              boosted: this.mobileMenuDisplayed,
              whaleType: "outline",
              depth: -(i*5),
              scale: WHALE_HEIGHT
            })

            rand_v = (new Vector2()).random(40, 10);
            const whale_inv = new WhaleSvg({
              parentEl: parentEl,
              startPos: inverted_pos[i],
              speed: rand_v,
              boosted: this.mobileMenuDisplayed,
              whaleType: "inverted",
              depth: -20 - (i*5),
              scale: WHALE_HEIGHT
            })

            this.animatedElementAr.push(whale, whale_inv);
        }

        for(let el of this.animatedElementAr){
            el.spawnElement();
            el.animate();
        }
    }
}
