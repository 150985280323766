import {ChangeDetectorRef, Component, HostListener, OnInit, AfterViewInit, Inject} from "@angular/core";

import {TranslationService} from "./translation.service";
import {GoogleAnalyticsService} from "./google-analytics.service";
import {WindowRefService} from "./window-ref.service";
import { NgxSpinnerService } from "ngx-spinner";
import {LoaderService} from "./loader.service";
import {NavLink} from "./nav-link.service";

import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';


@Component({
    selector: "app-component",
    templateUrl: "./app.component.html",
    styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit, AfterViewInit{
    public localeChange: boolean = false;
    public links: NavLink[];
    public mobileMenuDisplayed: boolean = false;
    public toplevelRoute: string;

    constructor(
      private ga: GoogleAnalyticsService,
      private router: Router,
      private transService: TranslationService,
      private cdRef: ChangeDetectorRef,
      private spinner: NgxSpinnerService,
      private windowRef: WindowRefService,
      private loader: LoaderService
    ) {
      this.links = [];
    }

    ngAfterViewInit () {
      this.setMobileVh();
    }

    ngOnInit () {

      this.links = [
        {
          transLabel: 'GAMES_LABEL',
          ref: '/games',
          sublinks: [
            {
              label: 'Rise: Strategy Evolved',
              ref: '/games/rise'
            }
          ]
        },
        { 
          transLabel: 'STUDIO_LABEL',
          ref: '/studio'
        },      
        {
            transLabel: `JOBS_LABEL`,
            ref: `/jobs`
        },
        {
            transLabel: "DEVBLOG_LABEL",
            ref: `/devblog`
        }
      ];

      //Always hide the mobile nav menu on route change
      this.router.events.subscribe( (event: any) => {
        switch (true) {
          case event instanceof NavigationStart:
            this.loader.keepSpinning();
            break;
          case event instanceof NavigationEnd:
          case event instanceof NavigationCancel:
          case event instanceof NavigationError: {

            // take only the first segment of the URL
            this.toplevelRoute = /^\/(.*?)(?:[\/\?#]{1}?.*?|$)/g.exec(event.url)[1];

            if(!this.toplevelRoute){
              this.toplevelRoute = 'root'
            }
            this.ga.registerPageView(event.url);
            this.hideMobileMenu();
            this.loader.keepSpinning();
            this.setMobileVh();
            break;
          }
          default: {
            break;
          }
        }
      });

      this.transService.localeChangeStream.subscribe(() => {
          this.localeChanged();
      });

      this.setMobileVh();
    }

    @HostListener('window:resize')
    public setMobileVh(){
        if(this.windowRef.nativeWindow === null){
          return;
        }
        let vh = this.windowRef.nativeWindow.innerHeight * 0.01;
        this.windowRef.nativeWindow.document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    public toggleMobileMenu(){
        this.mobileMenuDisplayed = !this.mobileMenuDisplayed;
    }

    @HostListener('window:mouseup', ['$event.target'])
    @HostListener('window:touchend', ['$event.target'])
    public hideMobileMenu(target?: HTMLElement){
        let el = target;
        while(el){
          if(el.getAttribute('class') == "mobile-menu"){
            return;
          }

          if(el.nodeName == "NAV"){
            return;
          }
          el = el.parentElement;
        }
          
        this.mobileMenuDisplayed = false;
    }

    public displayAnimation(): boolean{
      const rightPage = ['root', 'landing', 'contact'].indexOf(this.toplevelRoute) >= 0;
      return (rightPage || this.mobileMenuDisplayed);
    }

    private localeChanged(){
        this.localeChange = true;
        this.cdRef.detectChanges();
        this.localeChange = false;
        this.hideMobileMenu();
        this.cdRef.detectChanges();
        this.cdRef.markForCheck();
    }
};
