<div class="animation-container">
  <div 
    *ngIf="displayAnimation()" 
    [ngClass]="{'mobile-menu-active': mobileMenuDisplayed}"
    app-landing-animation [mobileMenuDisplayed]="mobileMenuDisplayed"
    app-mouse-swivel></div>
</div>

<div 
  id="left" 
  [attr.page]="toplevelRoute"
  [ngClass]="{'mobile-menu-active': mobileMenuDisplayed}">
  <div class="logo-container" [routerLink]="['/']">
    &nbsp;
    <img [src]="'assets/WindfishLogo.svg'" class="logo">
  </div>
</div>

<div 
  id="content" 
  *ngIf="!localeChange" 
  [attr.page]="toplevelRoute"
  [ngClass]="{'mobile-menu-active': mobileMenuDisplayed}">
  <header>
    <nav>
      <app-nav-link *ngFor="let link of links" [link]="link"></app-nav-link>

      <a [routerLink]="'/contact'" class="mobile contact" routerLinkActive="active">
        {{'CONTACT' | translation | async}}
      </a>

      <a class="mobile locale-select"></a>
    </nav>
  </header>
  <article>
    <ngx-spinner [type]="'square-spin'"></ngx-spinner>
    <router-outlet></router-outlet>
  </article>
</div>

<div id="right" *ngIf="!localeChange" [attr.page]="toplevelRoute">
  <div (click)="toggleMobileMenu()" class="mobile-menu">
    <img *ngIf="mobileMenuDisplayed" [src]="'assets/back_arrows.svg'" />
    <span>{{!mobileMenuDisplayed? "MENU" : "BACK"}}</span>
  </div>
  <div class="right-bar" [routerLink]="['/studio']">
    RIDE THE WHALE 
    <img class="chevron" [src]="'assets/chevron.svg'">
  </div>
  <div class="right-margin">
    <a class="locale-select"></a>
    <a [routerLink]="['/contact']" class="contact-link" routerLinkActive="active">
      {{'CONTACT_LABEL' | translation | async}}
    </a>
  </div>
</div>

