import {Pipe} from "@angular/core";
import {TranslationService} from "./translation.service";

@Pipe({name: 'translation'})
export class TranslationSnippet {
    constructor(
        private trans: TranslationService
    ){}

    public async transform(value: string, args?: any): Promise<string> {
        return await this.trans.getTranslation(value);
    }
}
