import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoaderService } from "./loader.service";
import { environment } from "../environments/environment";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpEventType,
  HttpInterceptor
} from '@angular/common/http';
import { Subject, Observable, interval } from 'rxjs';
import { takeUntil, share, tap } from "rxjs/operators";

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  public count: number = 0;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoaderService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const stopBounce = new Subject<any>();
    const obs: Observable<HttpEvent<any>> = next.handle(request).pipe(tap({
      error: () => {stopBounce.next(true)},
      complete: () => {stopBounce.next(true)}
    }));

    if(!isPlatformBrowser(this.platformId)){
      return obs;
    }

    interval(environment.http_loader_bounce_interval)
      .pipe(takeUntil(stopBounce))
      .subscribe(() => {
        this.loader.keepSpinning();
      });

    return obs;
  }
}
