import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {markdown} from "markdown"; //(it's a bit fucking weird, but I'll take it)
import {WindowRefService} from "./window-ref.service";
import {HttpClient} from "@angular/common/http";
import {LoaderService} from "./loader.service";

export declare class APITranslation {
    id: number;
    name: string;
    locale: string;
    body: string;
}

@Injectable()
export class TranslationService {
    public locale: string;
    public localeChangeStream: Subject<string> = new Subject();
    private all_translations: {[locale: string]: {[key: string]: string}};
    private api_promise: Promise<Object>;

    public locales: Object = {
        'en': 'en-US',
        'es': 'es-ES'
    };

    public reverseLocaleMap: Object = {};

    constructor(
      private windowRef: WindowRefService,
      private httpClient: HttpClient,
      private loader: LoaderService
    ){
      this.all_translations = {};

      for(let shortname in this.locales){
          this.reverseLocaleMap[this.locales[shortname]] = shortname;
      }

      this.setInitialLocale();
    }

    private getBrowserLocale(): string | null {
      const navlang = this.windowRef.nativeWindow.navigator.language;
      
      for(let supportedLang of Object.values(this.locales)){
        if(navlang === supportedLang){
          return navlang;
        }
      }

      return null;
    }

    private getStorageLocale(): string {
        return this.windowRef.nativeWindow.localStorage.getItem('locale');
    }

    public setInitialLocale(){
        if(this.windowRef.nativeWindow){
          this.locale =             
            this.getStorageLocale() ||
            this.getBrowserLocale() ||
            this.locales['en'] ;
          this.windowRef.nativeWindow.localStorage.setItem('locale', this.locale);
        }else{
          this.locale = this.locales['en'];
        }

        this.localeChangeStream.next(this.locale);
    }

    public toggleLocale(){
        this.loader.keepSpinning();

        let localeKey = null;

        switch(this.locale){
            case 'en-US':
                localeKey = 'es';
                break;
            case 'es-ES':
                localeKey = 'en';
                break;
        }

        this.locale = this.locales[localeKey];
        if(this.windowRef.nativeWindow){
          this.windowRef.nativeWindow.localStorage.setItem('locale', this.locale);
        }

        this.localeChangeStream.next(this.locale);
    }

    public async getAllTranslations(): Promise<Object>{
      return this.api_promise = new Promise<Object>(async (resolve, reject) => {
          this.all_translations[this.locale] = {};

          //fetch all translations from the database if necessary depending on the selected lang
          const translations = await this.httpClient.get(`/assets/content/translations/${this.locale}.json`).toPromise();
          for(let trans_id in translations){
            const trans_o = translations[trans_id];
            this.all_translations[this.locale][trans_id] = trans_o;
          }

          resolve(this.all_translations[this.locale]);
          delete this.api_promise;
      });
    }

    public async getPageTranslation(name: string): Promise<string>{
      if(!this.all_translations[this.locale]){
        this.all_translations[this.locale] = {};
      }else if(this.all_translations[this.locale][name]){
        return this.all_translations[this.locale][name];
      }

      const markdown_raw = await this.httpClient.get(`/assets/content/pages/${this.locale}/${name.toUpperCase()}.md`, {responseType: 'text'}).toPromise();
      this.all_translations[this.locale][name] = markdown.toHTML(markdown_raw);

      return this.all_translations[this.locale][name];
    }

    public async getTranslation(name: string): Promise<string>{
        name = name.toUpperCase();

        const withLocaleTranslations = (localeTranslations: Object): string => {
            return localeTranslations[name];
        };

        if(this.api_promise)
            return this.api_promise.then(withLocaleTranslations);

        if(this.all_translations[this.locale])
            return this.all_translations[this.locale][name];


        return this.getAllTranslations().then(withLocaleTranslations);

    }
}
