import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";

export interface TechLogo {
  logo_path: string
  ext_link: string
}

@Injectable({
  providedIn: 'root'
})
export class TechLogoService {
  constructor(private httpClient: HttpClient) { }

  public async getTechLogos(): Promise<TechLogo[]> {
    return this.httpClient.get<TechLogo[]>(`/assets/content/tech-logos.json`).toPromise();
  }

}
